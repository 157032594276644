<template>
    <div class="app-login">
        <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
            <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
        </v-overlay>

        <v-container px-5 pb-6 v-if="!resetSent && !error" class="dark--text">
            <form @submit.prevent="submit">
                <v-row>
                    <v-col cols="12" class="shrink">
                        <h2 class="text-no-wrap text-center my-6">Reset Password</h2>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <p class="x-small--text text-uppercase"><b>Email Address</b></p>
                        <v-text-field
                            ref="email"
                            v-model="email"
                            type="email"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-email-outline"
                            label=""
                            placeholder="Please enter your email..."
                            hint="Enter your email to reset your password"
                            required
                            :rules="[rules.required, rules.email]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block large color="primary" type="submit">Send Reset Link</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-btn :to="{ name: 'auth.login' }" small text color="dark">
                            <span class="text-capitalize font-weight-regular">Log In</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>

        <v-container px-5 pb-6 v-else-if="error" class="dark--text">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Reset Password</h2>
                    <p class="text-muted text-center dark--text">
                        An error occured while trying to send your password reset request. Please try again.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" @click="error = false">Try Again</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <v-btn :to="{ name: 'auth.login' }" small text color="dark">
                        <span class="text-capitalize font-weight-regular">Log In</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container px-5 pb-6 v-else class="dark--text">
            <v-row>
                <v-col class="shrink" cols="12">
                    <h2 class="text-no-wrap text-center mt-6 mb-3">Reset Password</h2>
                    <p class="text-muted text-center dark--text">A password reset email has been sent.</p>
                    <p class="text-muted text-center dark--text">
                        It may take a few minutes to recieve this. If you do not recieve this please check that you have
                        entered the correct email address or check your junk mail.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" :to="{ name: 'auth.login' }">Back to Log in</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";

/////////////////////////////////////////////////////////

export default {
    name: "forgot",

    mixins: [UserMixin],

    meta: {
        title: "Forgot Password",
    },

    mounted() {
        if (!this.email || !this.email.length) {
            return this.$refs.email.focus();
        }
    },

    data() {
        return {
            loading: false,
            disabled: false,
            resetSent: false,
            error: false,
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    methods: {
        async submit() {
            this.loading = true;
            const url = `https://${this.orgData.data.links.awards}/fluro/application/forgot`;
            const email = this.email;
            const payload = {
                username: email,
                redirect: "/auth/reset",
                emial: email,
            };
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            };
            const reset = await fetch(url, options);
            const status = reset.status;
            if (status == 200) {
                this.loading = false;
                this.resetSent = true;
            } else {
                this.error = true;
                this.loading = false;
            }

            //Check if there is an application
            // var applicationContext = this.application && this.application.authenticationStyle == "application";
            // console.log("application context", applicationContext);
            // this.$fluro.auth
            //     .sendResetPasswordRequest(
            //         {
            //             username: this.email,
            //             //This is the route that will receive the token
            //             //for the user to reset their password
            //             redirect: `/auth/reset`,
            //             mail: {
            //                 from: `${this.orgData.title} <hello@australianphotographicprize.com.au>`,
            //                 subject: "Reset your password",
            //                 text:
            //                     "<p>Hi" +
            //                     `,</p><p>Please click the link below to reset your password. If you did not request this, please ignore this email or get in contact with ${this.orgData.title}.</p>`,
            //             },
            //         },
            //         {
            //             bypassInterceptor: true,
            //             application: applicationContext, //Reset from the application
            //         }
            //     )
            //     .then(
            //         () => {
            //             this.resetSent = true;
            //             this.loading = false;
            //         },
            //         () => {
            //             this.error = true;
            //             this.loading = false;
            //         }
            //     );
        },
    },

    computed: {
        // ...mapState(['email']),
        orgData() {
            return this.$store.getters["org/getOrgData"];
        },
        email: {
            set(email) {
                this.$store.commit("email", email);
            },
            get(email) {
                return this.$store.getters.email;
            },
        },
    },
};
</script>
